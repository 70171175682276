<template>
  <vx-table-simple
    :items="items"
    :columns="columns"
  >
    <template #title>
      Last active generation
    </template>
    <template #actions>
      <vx-button
        v-if="lastActiveGenerationId"
        :to="lastSuccessfulGenerationUrl"
        variant="primary"
      >
        Link
      </vx-button>
    </template>
  </vx-table-simple>
</template>

<script>
import { VxCellDate, VxTableSimple } from '@/components/table'
import { inject } from '@vue/composition-api'
import { toArray } from '@/services/utils'
import { VxButton } from '@/components/buttons'

export default {
  name: 'CaseContentGeneration',
  components: {
    VxTableSimple,
    VxCellDate,
    VxButton
  },
  setup () {
    const {
      chest,
      lastActiveGenerationId,
      lastSuccessfulGenerationUrl
    } = inject('caseStore')

    const items = toArray(chest.last_active_generation || [])
    const columns = [
      {
        key: 'is_published',
        label: 'Last status',
        formatter: (value, key, { is_published, is_failed, fail_reason }) => {
          const publishing = is_published ? 'Published' : 'Not published'
          const status = is_failed ? 'Disabled' : 'Enabled'
          const failReason = fail_reason || ''

          return `${publishing}, ${status} ${failReason}`
        }
      },
      {
        key: 'min_category_amount',
        label: 'Items count (available on market)',
        formatter: (min_category_amount, key, { real_category_amount }) => {
          return `${min_category_amount} (${real_category_amount})`
        }
      },
      {
        key: 'created_at',
        label: 'Generated at',
        component: VxCellDate
      }
    ]

    return {
      items,
      columns,
      lastActiveGenerationId,
      lastSuccessfulGenerationUrl
    }
  }
}
</script>
