import { cases, categories, useResource } from '@/services/resources'
import { ref, reactive, watch } from '@vue/composition-api'
import { differenceBy, sortBy } from 'lodash'
import { filterTypes, useFiltersBase } from '@/services/table'

const useAllCategories = () => {
  const filters = [
    {
      key: 'name',
      label: 'Weapon',
      type: filterTypes.serverSelect,
      resource: categories.getNames,
      resourceMapper: ({ names }) => names.sort()
    },
    {
      key: 'variation',
      label: 'Skin',
      type: filterTypes.serverSelect,
      resource: categories.getVariations,
      resourceMapper: ({ variations }) => variations.sort()
    },
    {
      key: 'rarity',
      type: filterTypes.serverSelect,
      resource: categories.getBaseInfo,
      resourceMapper: ({ rarity }) => rarity,
      optionsLabel: 'full',
      reduce: ({ short }) => short
    }
  ]

  const filtersValue = reactive({})

  const { setFilters, filterDefs, filterObjectByEmpty } = useFiltersBase(filtersValue, filters)
  setFilters()

  const { loading, can, callRequest } = useResource(categories.getAll)

  const currentPage = ref(0)
  const hasNextPage = ref(false)
  const allCategories = ref([])

  const getAllCategories = async (page) => {
    const payload = {
      params: {
        per_page: 100,
        page: page || currentPage.value + 1,
        include: 'file,itemStats',
        filter: filterObjectByEmpty(filtersValue)
      }
    }

    const [err, res] = await callRequest(payload)
    if (err) return null

    const { current_page, next_page_url } = res

    hasNextPage.value = !!next_page_url

    if (current_page === 1) {
      allCategories.value = []
    }

    currentPage.value = current_page

    return res
  }

  const backToFrontMapper = (all, selected) => {
    return differenceBy(all, selected, 'id')
  }

  return {
    can,
    loading,

    currentPage,
    hasNextPage,

    allCategories,
    getAllCategories,
    backToFrontMapper,

    filterDefs,
    filtersValue
  }
}

const useSelectedCategories = (chestId) => {
  const {
    loading: getLoading,
    can: canGet,
    callRequest: getCallRequest
  } = useResource(cases.categories)

  const {
    loading: updateLoading,
    can: canUpdate,
    callRequest: updateCallRequest
  } = useResource(cases.updateCategories)

  const selectedCategories = ref([])

  const getCaseCategories = async () => {
    const payload = {
      urlParams: { id: chestId },
      params: {
        per_page: 500,
        include: 'category,category.file'
      }
    }

    const [err, res] = await getCallRequest(payload)
    if (err) return null

    return res
  }

  const backToFrontMapper = (value) => {
    return sortBy(value, 'position').map(({ category }) => category)
  }

  const frontToBackMapper = (categories) => {
    return categories.map(({ id }, index) => ({
      category_id: id,
      position: ++index
    }))
  }

  const updateCaseCategories = async () => {
    const data = { categories: frontToBackMapper(selectedCategories.value) }
    await updateCallRequest({ urlParams: { id: chestId }, data })
  }

  return {
    canGet,
    getLoading,

    selectedCategories,
    backToFrontMapper,
    getCaseCategories,

    canUpdate,
    updateLoading,
    updateCaseCategories
  }
}

const useContentBuilderStore = (chestId) => {
  const {
    canGet: canGetCaseCategories,
    getLoading: getCaseCategoriesLoading,
    selectedCategories,
    getCaseCategories,
    backToFrontMapper: selectedBackToFrontMapper,

    canUpdate: canUpdateCaseCategories,
    updateLoading: updateCaseCategoriesLoading,
    updateCaseCategories
  } = useSelectedCategories(chestId)

  const {
    can: canGetAllCategories,
    loading: getAllCategoriesLoading,

    currentPage: allCategoriesPage,
    hasNextPage: allCategoriesHasNextPage,

    allCategories,
    getAllCategories,
    backToFrontMapper: allBackToFrontMapper,

    filterDefs,
    filtersValue
  } = useAllCategories()

  watch(
    () => filtersValue,
    async () => {
      const res = await getAllCategories(1)
      allCategories.value = allBackToFrontMapper(res.data, selectedCategories.value)
    },
    { deep: true }
  )

  const loadMoreAllCategories = async () => {
    const res = await getAllCategories()
    allCategories.value.push(...allBackToFrontMapper(res.data, selectedCategories.value))
  }

  const initCategoriesRequest = async () => {
    Promise.all([
      getCaseCategories(),
      getAllCategories()
    ]).then(([selected, all]) => {
      if (selected) {
        selectedCategories.value = selectedBackToFrontMapper(selected.data)
      }
      if (all) {
        allCategories.value = allBackToFrontMapper(all.data, selectedCategories.value)
      }
    }).catch()
  }

  const removeFromSelected = (category) => {
    const position = selectedCategories.value.indexOf(category)
    selectedCategories.value.splice(position, 1)
    allCategories.value.push(category)
  }

  const addToSelected = (category) => {
    const position = allCategories.value.indexOf(category)
    allCategories.value.splice(position, 1)
    selectedCategories.value.push(category)
  }

  const reverseOrderOfSelected = () => {
    selectedCategories.value = selectedCategories.value.reverse()
  }

  return {
    canGetCaseCategories,
    getCaseCategoriesLoading,
    selectedCategories,

    canGetAllCategories,
    getAllCategoriesLoading,
    allCategories,
    allCategoriesPage,
    allCategoriesHasNextPage,
    loadMoreAllCategories,

    filterDefs,
    filtersValue,

    initCategoriesRequest,

    removeFromSelected,
    addToSelected,
    reverseOrderOfSelected,

    canUpdateCaseCategories,
    updateCaseCategoriesLoading,
    updateCaseCategories
  }
}

export default useContentBuilderStore
