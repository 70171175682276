var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vx-form',{attrs:{"resource":_vm.formResource},on:{"submit":_vm.handleSubmit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
var can = ref.can;
return [_c('vx-select',{attrs:{"rules":_vm.rules.is_manual,"options":_vm.manualOptions,"reduce":function (ref) {
	var value = ref.value;

	return value;
},"options-label":"name","label":"Is Manual","name":"is_manual"},model:{value:(_vm.formValue.is_manual),callback:function ($$v) {_vm.$set(_vm.formValue, "is_manual", $$v)},expression:"formValue.is_manual"}}),_c('vx-input',{attrs:{"rules":_vm.rules.min_category_amount,"disabled":_vm.isManual,"label":"Min category amount","name":"min_category_amount"},model:{value:(_vm.formValue.min_category_amount),callback:function ($$v) {_vm.$set(_vm.formValue, "min_category_amount", $$v)},expression:"formValue.min_category_amount"}}),_c('vx-input',{attrs:{"rules":_vm.rules.profitability,"disabled":_vm.isManual,"label":"Profitability","name":"profitability","type":"number","step":"0.01"},model:{value:(_vm.formValue.profitability),callback:function ($$v) {_vm.$set(_vm.formValue, "profitability", $$v)},expression:"formValue.profitability"}}),_c('vx-input',{attrs:{"rules":_vm.rules.generation_period,"disabled":_vm.isManual,"label":"Generation period (hours / max is 72)","name":"generation_period","type":"number","step":"1"},model:{value:(_vm.formValue.generation_period),callback:function ($$v) {_vm.$set(_vm.formValue, "generation_period", $$v)},expression:"formValue.generation_period"}}),_c('vx-input',{attrs:{"rules":_vm.rules.min_expensive_chance,"disabled":_vm.isManual,"label":"Min Expensive Chance (percents)","name":"min_expensive_chance","type":"number"},model:{value:(_vm.formValue.min_expensive_chance),callback:function ($$v) {_vm.$set(_vm.formValue, "min_expensive_chance", $$v)},expression:"formValue.min_expensive_chance"}}),_c('vx-button',{attrs:{"loading":loading,"can":can,"variant":"primary","type":"submit"}},[_vm._v(" Save ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }