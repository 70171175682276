<template>
  <vx-form
    v-slot="{ loading, can }"
    :resource="formResource"
    @submit="handleSubmit"
  >
    <vx-select
      v-model="formValue.is_manual"
      :rules="rules.is_manual"
      :options="manualOptions"
      :reduce="({ value }) => value"
      options-label="name"
      label="Is Manual"
      name="is_manual"
    />
    <vx-input
      v-model="formValue.min_category_amount"
      :rules="rules.min_category_amount"
      :disabled="isManual"
      label="Min category amount"
      name="min_category_amount"
    />
    <vx-input
      v-model="formValue.profitability"
      :rules="rules.profitability"
      :disabled="isManual"
      label="Profitability"
      name="profitability"
      type="number"
      step="0.01"
    />
    <vx-input
      v-model="formValue.generation_period"
      :rules="rules.generation_period"
      :disabled="isManual"
      label="Generation period (hours / max is 72)"
      name="generation_period"
      type="number"
      step="1"
    />
    <vx-input
      v-model="formValue.min_expensive_chance"
      :rules="rules.min_expensive_chance"
      :disabled="isManual"
      label="Min Expensive Chance (percents)"
      name="min_expensive_chance"
      type="number"
    />
    <vx-button
      :loading="loading"
      :can="can"
      variant="primary"
      type="submit"
    >
      Save
    </vx-button>
  </vx-form>
</template>

<script>
import { VxForm, VxInput, VxSelect } from '@/components/form'
import VxButton from '@/components/buttons/VxButton'
import { reactive, computed, inject, watch } from '@vue/composition-api'
import {
  formatFromHoursToSeconds,
  formatFromSecondsToHours,
  integerToPercentage,
  percentageToInteger
} from '@/services/utils'
import { apiDefaultValidators, setValuesToForm } from '@/services/form'
import { cases, passDataToResource } from '@/services/resources'

export default {
  name: 'GenerationSettingsEditor',
  components: {
    VxForm,
    VxInput,
    VxButton,
    VxSelect
  },
  setup (props, { emit }) {
    const {
      chest,
      isBroCoinType,
      updateChest
    } = inject('caseStore')

    const manualOptions = [
      {
        name: 'No',
        value: false
      },
      {
        name: 'Yes',
        value: true
      }
    ]

    const formValue = reactive({
      is_manual: false,
      min_category_amount: 0,
      generation_period: null,
      profitability: 0.9,
      min_expensive_chance: 0
    })

    watch(
      () => formValue.is_manual,
      (value) => {
        if (value) {
          formValue.generation_period = null
          formValue.profitability = null
        } else {
          formValue.generation_period = formatFromSecondsToHours(chest.generation_period)
          formValue.profitability = chest.profitability
        }
      })

    const handleSubmit = ([err, res]) => {
      if (err) return

      updateChest(res.data)
      emit('submit', res)
    }

    const getOptions = () => {
      if (!isBroCoinType.value) return { options: chest.options }
      const {
        amount,
        currency
      } = chest.options.price
      return {
        options: {
          price: amount,
          currency_code: currency
        }
      }
    }

    const frontToBackMapper = ({
      generation_period,
      min_expensive_chance,
      ...restFields
    }) => {
      const {
        profitability,
        slug,
        title,
        file_id,
        icon_type,
        main_price,
        type: chest_type_id
      } = chest
      const extraParams = {
        profitability: formValue.profitability || profitability,
        slug,
        title,
        main_price,
        file_id,
        icon_type,
        ...(chest_type_id && { ...getOptions() }),
        chest_type_id: chest_type_id?.id || null
      }

      return {
        ...restFields,
        generation_period: formatFromHoursToSeconds(generation_period),
        min_expensive_chance: percentageToInteger(min_expensive_chance),
        ...extraParams
      }
    }

    const formResource = passDataToResource(cases.update, {
      frontToBackMapper,
      requestParams: {
        urlParams: { id: chest.id }
      }
    })

    const isManual = computed(() => formValue.is_manual)

    const rules = {
      is_manual: {
        required: true
      },
      min_category_amount: {
        required: true,
        numeric: true,
        min_value: 0,
        max_value: apiDefaultValidators.maxSmallInt
      },
      profitability: {
        between: [0.7, 1]
      },
      generation_period: {
        integer: true,
        min_value: 1,
        max_value: 72
      },
      min_expensive_chance: {
        required: true,
        min: 0,
        max: 100
      }
    }

    const backToFrontMapper = ({
      is_manual,
      min_category_amount,
      generation_period,
      profitability,
      min_expensive_chance
    }) => {
      return {
        is_manual,
        min_category_amount,
        profitability,
        generation_period: formatFromSecondsToHours(generation_period),
        min_expensive_chance: integerToPercentage(min_expensive_chance)
      }
    }

    setValuesToForm(backToFrontMapper(chest), formValue)

    return {
      formResource,
      formValue,
      rules,
      handleSubmit,

      isManual,
      manualOptions
    }
  }
}
</script>
