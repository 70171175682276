<template>
  <case-container>
    <selected-categories />
    <available-categories />
  </case-container>
</template>

<script>
import useCaseStore from '@/views/cases/case/useCaseStore'
import { onBeforeMount, provide } from '@vue/composition-api'
import SelectedCategories from '@/views/cases/case/content-builder/SelectedCategories'
import AvailableCategories from '@/views/cases/case/content-builder/AvailableCategories'
import useContentBuilderStore from '@/views/cases/case/content-builder/contentBuilderStore'

export default {
  name: 'ContentBuilder',
  components: {
    AvailableCategories,
    SelectedCategories,
    CaseContainer: () => import('@/views/cases/case/CaseContainer')
  },
  props: {
    dataResolverResponse: {
      type: Object,
      required: true
    }
  },
  setup ({ dataResolverResponse }) {
    const caseStore = useCaseStore(dataResolverResponse)
    const { chest } = caseStore
    provide('caseStore', caseStore)

    const contentBuilderStore = useContentBuilderStore(chest.id)
    const { initCategoriesRequest } = contentBuilderStore
    provide('contentBuilderStore', contentBuilderStore)

    onBeforeMount(() => {
      initCategoriesRequest()
    })
  }
}
</script>
