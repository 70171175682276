<template>
  <case-container>
    <b-row>
      <b-col cols="12" md="6">
        <case-details-table />
      </b-col>
      <b-col cols="12" md="6">
        <case-content-generation class="mb-2" />
        <case-prices />
      </b-col>
    </b-row>
  </case-container>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import CaseUpdateEditor from '@/views/cases/case/details/CaseUpdateEditor'
import CaseContentGeneration from '@/views/cases/case/details/CaseContentGeneration'
import CasePrices from '@/views/cases/case/details/CasePrices'
import CaseDetailsTable from '@/views/cases/case/details/CaseDetailsTable'
import useCaseStore from '@/views/cases/case/useCaseStore'
import { provide } from '@vue/composition-api'

export default {
  name: 'CaseDetails',
  components: {
    CaseContainer: () => import('@/views/cases/case/CaseContainer'),
    BCol,
    BRow,
    CasePrices,
    CaseContentGeneration,
    CaseUpdateEditor,
    CaseDetailsTable
  },
  props: {
    dataResolverResponse: {
      type: Object,
      required: true
    }
  },
  setup ({ dataResolverResponse }) {
    const caseStore = useCaseStore(dataResolverResponse)
    provide('caseStore', caseStore)
  }
}
</script>
