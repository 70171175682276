<template>
  <div class="category-card card width-180 mb-0">
    <div class="overlay-img-card">
      <b-img-lazy
        v-if="imageUrl"
        fluid
        class="card-img-top p-1"
        :src="imageUrl"
      />
      <div class="card-img-overlay">
        <div
          v-b-tooltip.hover.top="'Item price range'"
          class="info-block price-range"
        >
          {{ priceRange }}
        </div>
        <div class="event">
          <feather-icon
            v-if="addButton"
            icon="PlusCircleIcon"
            class="text-success add-btn"
            @click="$emit('add', category)"
          />
          <feather-icon
            v-if="removeButton"
            icon="MinusCircleIcon"
            class="text-danger remove-btn"
            @click="$emit('remove', category)"
          />
        </div>
        <div
          v-b-tooltip.hover.top="`Rarity: ${category.rarity}`"
          class="rarity"
          :class="`rarity-bg-${category.rarity}`"
        />
        <div
          v-b-tooltip.hover.top="'Currently available amount'"
          class="info-block available-amount"
        >
          {{ category.available_count }}
        </div>
      </div>
    </div>
    <div class="p-1 d-flex flex-column">
      <span>{{ category.name }}</span>
      <span>{{ category.variation }}</span>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { moneyFormat } from '@/services/utils'
import { BImgLazy } from 'bootstrap-vue'

export default {
  name: 'CategoryItemCard',
  components: {
    BImgLazy
  },
  props: {
    addButton: Boolean,
    removeButton: Boolean,
    category: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const priceRange = computed(() => {
      const minPrice = moneyFormat(props.category.min_price, { withSpace: false })
      const maxPrice = moneyFormat(props.category.max_price, { withSpace: false })
      return `${minPrice} - ${maxPrice}`
    })

    const imageUrl = computed(() => props.category?.file?.external_link)

    return {
      priceRange,
      imageUrl
    }
  }
}
</script>

<style lang="scss" scoped>
.category-card {
  .overlay-img-card {
    position: relative;

    img {
      height: 140px;
    }

    div {
      &.price-range {
        position: absolute;
        top: 0;
        left: 0;
      }

      &.event {
        position: absolute;
        top: 3px;
        right: 4px;

        svg {
          width: 1.5rem;
          height: 1.5rem;
          cursor: pointer;
        }
      }

      &.available-amount {
        position: absolute;
        bottom: 0;
        right: 0;
      }

      &.rarity {
        position: absolute;
        bottom: 4px;
        left: 4px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }
    }
  }
}
</style>
