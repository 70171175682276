<template>
  <vx-table-filters
    v-model="filtersValue"
    :filters="filterDefs"
    without-title
  />
</template>

<script>
import VxTableFilters from '@/components/table/vx-table-filters/VxTableFilters'
import { inject } from '@vue/composition-api'

export default {
  name: 'AvailableCategoriesFilter',
  components: {
    VxTableFilters
  },
  setup () {
    const {
      filterDefs,
      filtersValue
    } = inject('contentBuilderStore')

    return {
      filterDefs,
      filtersValue
    }
  }
}
</script>
