<template>
  <case-container>
    <vx-table
      :resource="resource"
      :columns="columns"
      :prefetch="getBaseInfo"
      :details-component="CategoryDetails"
      suppress-route-handling
    >
      <template #title>
        Categories
      </template>
    </vx-table>
  </case-container>
</template>

<script>
import { VxCellDetailsToggler, VxCellImage, VxCellRarityBadge, VxTable } from '@/components/table'
import { cases, passDataToResource } from '@/services/resources'
import CategoryDetails from '@/views/cases/case/items-settings/CategoryDetails'
import useCaseStore from '@/views/cases/case/useCaseStore'

export default {
  name: 'ItemsSettings',
  components: {
    CaseContainer: () => import('@/views/cases/case/CaseContainer'),
    VxTable,
    VxCellImage,
    CategoryDetails,
    VxCellDetailsToggler
  },
  props: {
    dataResolverResponse: {
      type: Object,
      required: true
    }
  },
  setup ({ dataResolverResponse }) {
    const {
      chest,
      getBaseInfo
    } = useCaseStore(dataResolverResponse)

    const resource = passDataToResource(cases.categories, {
      requestParams: {
        urlParams: { id: chest.id },
        params: { include: 'category,category.file' }
      }
    })

    const columns = [
      {
        key: 'details',
        label: '',
        component: VxCellDetailsToggler,
        tdClass: ['p-0', 'width-50']
      },
      {
        key: 'category_id',
        label: 'Id'
      },
      {
        key: 'category.file',
        label: 'Image',
        component: VxCellImage
      },
      {
        key: 'category.name',
        label: 'Name'
      },
      {
        key: 'category.variation',
        label: 'Variation'
      },
      {
        key: 'category.rarity',
        label: 'Rarity',
        component: VxCellRarityBadge
      }
    ]

    return {
      resource,
      columns,
      getBaseInfo,
      CategoryDetails
    }
  }
}
</script>
