import { ref } from '@vue/composition-api'
import { isEmpty } from 'lodash'
import { categories, useResource } from '@/services/resources'

const useBaseInfo = () => {
  const rarities = ref({})
  const exteriors = ref({})
  const exteriorEmptyKey = 'Vanilla'

  const getBaseInfo = async () => {
    if (!isEmpty(rarities.value) || !isEmpty(exteriors.value)) {
      return
    }

    const { can, callRequest: getBaseInfoRequest } = useResource(categories.getBaseInfo)
    if (!can) return

    const [err, res] = await getBaseInfoRequest()
    if (err) return

    const { data } = res

    exteriors.value = data.exteriors.reduce((res, { short, full }) => {
      res[short === '' ? full : short] = { short, full }
      return res
    }, {})

    rarities.value = data.rarity.reduce((res, { short, full }) => {
      res[short] = { short, full }
      return res
    }, {})
  }

  const exteriorFormatter = (exterior) => {
    return exteriors.value[exterior || exteriorEmptyKey]?.full || exterior
  }

  const rarityFormatter = (rarity) => {
    return rarities.value[rarity]?.full || rarity
  }

  return {
    rarities,
    exteriors,
    exteriorEmptyKey,
    getBaseInfo,
    exteriorFormatter,
    rarityFormatter
  }
}

export default useBaseInfo
