<template>
  <vx-table
    :resource="resource"
    :columns="columns"
    :data-mapper="(value) => [value]"
    stacked
    suppress-route-handling
  >
    <template #title>
      Content
    </template>
  </vx-table>
</template>

<script>
import { VxTable, VxCellDate, VxCellMoney, VxCellBadges } from '@/components/table'
import { cases, passDataToResource } from '@/services/resources'
import { boolStatusFormat, chanceFormat, getMainPrice, nullFormatter } from '@/services/utils'
import { getProfitabilityClasses } from '@/views/cases/casesService'
import VxButton from '@/components/buttons/VxButton'
import { inject } from '@vue/composition-api'

export default {
  name: 'GeneratedContentDetails',
  components: {
    VxButton,
    VxTable,
    VxCellDate,
    VxCellMoney,
    VxCellBadges
  },
  props: {
    generationId: {
      type: Number,
      required: true
    }
  },
  setup ({ generationId }) {
    const { chest } = inject('caseStore')

    const resource = passDataToResource(cases.getGeneration, {
      requestParams: {
        urlParams: { chestId: chest.id, generationId }
      }
    })

    const columns = [
      { key: 'id' },
      { key: 'uid' },
      {
        key: 'prices',
        label: 'Price',
        component: VxCellMoney,
        formatter: getMainPrice
      },
      {
        key: 'created_at',
        component: VxCellDate
      },
      {
        key: 'finished_at',
        component: VxCellDate
      },
      {
        key: 'real_category_amount',
        label: 'Category used (required)',
        formatter: (value, key, { min_category_amount }) => {
          return `${value} (${min_category_amount})`
        }
      },
      {
        key: 'real_expensive_chance',
        label: 'Expensive items chance (required)',
        formatter: (value, key, { min_expensive_chance }) => {
          return `${chanceFormat(value)} (${chanceFormat(min_expensive_chance)})`
        }
      },
      {
        key: 'is_auto',
        label: 'Auto',
        formatter: boolStatusFormat
      },
      {
        key: 'real_profitability',
        label: 'Real profitability',
        formatter: (value, key, { desired_profitability }) => {
          return `${nullFormatter(value)} (${nullFormatter(desired_profitability)})`
        },
        tdClass: (value) => getProfitabilityClasses(value)
      },
      {
        key: 'gamma_shape',
        label: 'Shape parameter (k)',
        formatter: nullFormatter
      },
      {
        key: 'gamma_scale',
        label: 'Scale parameter',
        formatter: nullFormatter
      },
      {
        key: 'is_failed',
        label: 'Generation Result',
        component: VxCellBadges,
        tdAttr: {
          colors: {
            Success: 'success',
            Failed: 'danger'
          }
        },
        formatter: (value) => value ? 'Failed' : 'Success'
      },
      { key: 'fail_reason' }
    ]

    return {
      resource,
      columns
    }
  }
}
</script>
