<template>
  <vx-card
    :loading="getAllCategoriesLoading"
    :can="canGetAllCategories"
    no-body
  >
    <template #title>
      Available categories
    </template>
    <b-card-body>
      <available-categories-filter class="my-1" />

      <draggable
        :list="allCategories"
        tag="div"
        class="d-flex flex-wrap justify-content-center"
        group="categories"
      >
        <category-item-card
          v-for="(item, id) in allCategories"
          :key="id"
          :category="item"
          class="m-50"
          add-button
          @add="addToSelected"
        />
      </draggable>

      <div
        v-if="allCategoriesHasNextPage"
        class="w-100 mt-1 d-flex justify-content-center"
      >
        <vx-button
          variant="primary"
          @click="loadMoreAllCategories"
        >
          Load more
        </vx-button>
      </div>
    </b-card-body>
  </vx-card>
</template>

<script>
import VxCard from '@/components/VxCard'
import { BCardBody } from 'bootstrap-vue'
import Draggable from 'vuedraggable'
import VxButton from '@/components/buttons/VxButton'
import { inject } from '@vue/composition-api'
import AvailableCategoriesFilter from '@/views/cases/case/content-builder/AvailableCategoriesFilter'
import CategoryItemCard from '@/views/skins/CategoryItemCard'

export default {
  name: 'AvailableCategories',
  components: {
    CategoryItemCard,
    AvailableCategoriesFilter,
    VxButton,
    VxCard,
    BCardBody,
    Draggable
  },
  setup () {
    const {
      canGetAllCategories,
      getAllCategoriesLoading,

      allCategories,
      loadMoreAllCategories,

      allCategoriesPage,
      allCategoriesHasNextPage,

      addToSelected,

      filters,
      filtersValue
    } = inject('contentBuilderStore')

    return {
      canGetAllCategories,
      getAllCategoriesLoading,

      allCategories,

      allCategoriesPage,
      allCategoriesHasNextPage,
      loadMoreAllCategories,

      addToSelected,

      filters,
      filtersValue
    }
  }
}
</script>
