<template>
  <vx-table
    :resource="resource"
    :columns="columns"
    suppress-route-handling
    hide-per-page
  />
</template>

<script>
import { VxCellBadges, VxCellLink, VxTable } from '@/components/table'
import { inject } from '@vue/composition-api'
import { boolStatuses, cases, passDataToResource } from '@/services/resources'
import { buildRoute, path } from '@/router'
import { boolStatusFormat, chanceFormat, getMainPrice, moneyFormat } from '@/services/utils'

export default {
  name: 'GenerationContentCategoryDetails',
  components: {
    VxTable
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup ({ data: { fields, item } }) {
    const {
      chest,
      exteriorFormatter
    } = inject('caseStore')
    const generationId = fields[0].tdAttr?.generationId

    const resource = passDataToResource(cases.getGenerationContent, {
      requestParams: {
        urlParams: {
          chestId: chest.id,
          generationId
        },
        params: {
          filter: { category_id: item.id },
          include: 'item,item.entity'
        }
      }
    })

    const columns = [
      { key: 'id' },
      {
        key: 'item',
        component: VxCellLink,
        tdAttr: ({ id, title }) => ({
          label: title,
          subLabel: `#${id}`,
          href: buildRoute(path.items, { query: { id } })
        })
      },
      {
        key: 'item.entity.is_stattrak',
        label: 'Is stattrak',
        component: VxCellBadges,
        tdAttr: {
          colors: {
            [boolStatuses['1']]: 'success',
            [boolStatuses['0']]: 'warning'
          }
        },
        formatter: (value) => boolStatusFormat(value)
      },
      {
        key: 'item.entity.is_souvenir',
        label: 'Is souvenir',
        component: VxCellBadges,
        tdAttr: {
          colors: {
            [boolStatuses['1']]: 'success',
            [boolStatuses['0']]: 'warning'
          }
        },
        formatter: (isSouvenir) => boolStatusFormat(isSouvenir)
      },
      {
        key: 'item.entity.exterior',
        label: 'Exterior',
        formatter: exteriorFormatter
      },
      {
        key: 'fixed_price',
        label: 'Fixed price',
        sortable: true,
        formatter: (value, key, { item: { prices } }) => {
          return moneyFormat(getMainPrice(prices), { withSpace: false })
        }
      },
      {
        key: 'range_min',
        sortable: true
      },
      {
        key: 'range_max',
        sortable: true
      },
      {
        key: 'chance',
        sortable: true,
        formatter: chanceFormat
      }
    ]

    return {
      resource,
      columns
    }
  }
}
</script>
