<template>
  <vx-table
    :resource="resource"
    :columns="columns"
    :tbody-tr-class="rowClass"
    hide-per-page
    suppress-route-handling
  />
</template>

<script>
import { VxCellButtons, VxCellMoney, VxTable, VxCellItemChanceInput } from '@/components/table'
import { cases, passDataToResource } from '@/services/resources'
import { getMainPrice } from '@/services/utils'
import { buttons } from '@/components/table/vx-table-cells/vx-cell-button'
import { useSkin } from '@/services/app'

export default {
  name: 'CategoryDetails',
  components: {
    VxTable,
    VxCellButtons
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup ({ data: { item } }) {
    const resource = passDataToResource(cases.items, {
      requestParams: {
        urlParams: {
          id: item.chest_id
        },
        params: {
          include: 'item',
          filter: { category_id: item.category_id }
        }
      }
    })

    const columns = [
      {
        key: 'item_id',
        label: 'Id'
      },
      {
        key: 'item.title',
        label: 'Title'
      },
      {
        key: 'item.available',
        label: 'Count'
      },
      {
        key: 'item.prices',
        label: 'Price',
        component: VxCellMoney,
        formatter: (value) => {
          return getMainPrice(value)
        }
      },
      {
        key: 'predefined_chance',
        label: 'Manual chance',
        component: VxCellItemChanceInput
      },
      {
        key: 'is_disabled',
        label: 'Can drop',
        component: VxCellButtons,
        tdAttr: (value, key, item) => ({
          buttons: canDropActionButton(item)
        })
      },
      {
        key: 'is_required',
        label: 'Required',
        component: VxCellButtons,
        tdAttr: (value, key, item) => ({
          buttons: requiredActionButton(item)
        })
      }
    ]

    const actionButton = (value) => {
      return value
        ? { ...buttons.turnOff, label: 'Yes' }
        : { ...buttons.turnOn, label: 'No' }
    }

    const updateItemResource = ({ chest_id, item_id }, data) => {
      return passDataToResource(cases.updateItem, {
        requestParams: {
          urlParams: { chestId: chest_id, itemId: item_id },
          data
        }
      })
    }

    const canDropActionButton = ({ is_disabled, ...rest }) => {
      return [{
        ...actionButton(!is_disabled),
        resource: updateItemResource(rest, { is_disabled: !is_disabled })
      }]
    }

    const requiredActionButton = ({ is_required, ...rest }) => {
      return [{
        ...actionButton(is_required),
        resource: updateItemResource(rest, { is_required: !is_required })
      }]
    }

    const { skin, skins } = useSkin()

    const rowClass = (item) => {
      if (!item) return

      const accentClasses = {
        [skins.dark]: 'bg-darken-5',
        [skins.bordered]: 'bg-lighten-5'
      }
      const accentClass = accentClasses[skin.value]

      return [
        item.is_disabled ? 'bg-danger' : 'bg-success',
        accentClass
      ]
    }

    return {
      resource,
      columns,
      rowClass
    }
  }
}
</script>
