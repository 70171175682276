<template>
  <vx-table
    :resource="resource"
    :columns="columns"
    :prefetch="getBaseInfo"
    :details-component="GenerationContentCategoryDetails"
    suppress-route-handling
  />
</template>

<script>
import {
  VxCellBadges,
  VxCellDetailsToggler,
  VxCellImageWithTitle,
  VxCellRarityBadge,
  VxTable
} from '@/components/table'
import { inject } from '@vue/composition-api'
import { cases, passDataToResource } from '@/services/resources'
import { buildRoute, path } from '@/router'
import { chanceFormat, getMainPrice, moneyFormat } from '@/services/utils'
import GenerationContentCategoryDetails from '@/views/cases/case/generated-content/GenerationContentCategoryDetails'

export default {
  name: 'GenerationContentCategories',
  components: {
    VxTable,
    VxCellImageWithTitle,
    VxCellBadges
  },
  props: {
    generationId: {
      type: Number,
      required: true
    }
  },
  setup ({ generationId }) {
    const {
      chest,
      getBaseInfo
    } = inject('caseStore')

    const resource = passDataToResource(cases.getGenerationCategories, {
      requestParams: {
        urlParams: { chestId: chest.id, generationId },
        params: { include: 'itemStats,file' }
      }
    })

    const columns = [
      {
        key: 'details',
        label: '',
        component: VxCellDetailsToggler,
        tdClass: ['p-0', 'width-50'],
        tdAttr: { generationId }
      },
      {
        key: 'category',
        component: VxCellImageWithTitle,
        tdAttr: (value, key, { file: { external_link }, id, name, variation }) => {
          return {
            label: `${name} | ${variation}`,
            id: id,
            href: external_link,
            link: buildRoute(path.categories, { query: { id } })
          }
        }
      },
      {
        key: 'content_min_prices',
        label: 'Content price range',
        formatter: (value, key, { content_max_prices }) => {
          const min = moneyFormat(getMainPrice(value), { withSpace: false })
          const max = moneyFormat(getMainPrice(content_max_prices), { withSpace: false })
          return `${min} - ${max}`
        }
      },
      {
        key: 'total_generation_chance',
        formatter: chanceFormat
      },
      {
        key: 'rarity',
        label: 'Rarity',
        component: VxCellRarityBadge
      }
    ]

    return {
      resource,
      columns,
      getBaseInfo,
      GenerationContentCategoryDetails
    }
  }
}
</script>
