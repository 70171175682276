<template>
  <case-container>
    <b-row class="mb-2">
      <b-col>
        <b-alert v-if="isChestActive" show variant="danger">
          Content generation impossible because the case is active. Please disable it first.
        </b-alert>
        <vx-button
          v-else
          :can="canGenerate"
          :loading="generationLoading"
          variant="primary"
          class="mb-1 mb-md-0 mr-1"
          @click="generate"
        >
          {{ generationButtonText }}
        </vx-button>
        <vx-button
          variant="primary"
          :href="wdsUrl"
          class="mb-1 mb-md-0 mr-1"
        >
          WDS
        </vx-button>
        <vx-button
          v-if="showLastContent"
          :to="lastGenerationUrl"
          variant="primary"
          class="mb-1 mb-md-0 mr-1"
        >
          Last generation
        </vx-button>
        <vx-button
          v-if="showLastSuccessfulGeneration"
          :to="lastSuccessfulGenerationUrl"
          variant="primary"
        >
          Last successful generation
        </vx-button>
      </b-col>
      <b-col v-if="!generationId">
        <b-alert show variant="warning">
          Case content has never been generated, please click "Generate" button.
        </b-alert>
      </b-col>
    </b-row>
    <template v-if="generationId">
      <b-row class="mb-2">
        <b-col cols="12" md="5" class="mb-2 mb-md-0">
          <generated-content-details :generation-id="generationId" />
        </b-col>
        <b-col cols="12" md="7">
          <generated-content-borders :generation-id="generationId" />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-tabs>
            <b-tab lazy title="Items">
              <generation-content-items :generation-id="generationId" />
            </b-tab>
            <b-tab lazy title="Categories">
              <generation-content-categories :generation-id="generationId" />
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </template>
  </case-container>
</template>

<script>
import { computed, provide } from '@vue/composition-api'
import { buildRoute, path, useRouter, routerDataResolver } from '@/router'
import { BAlert, BCardBody, BCol, BRow, BTab, BTabs } from 'bootstrap-vue'
import VxButton from '@/components/buttons/VxButton'
import GeneratedContentDetails from '@/views/cases/case/generated-content/GeneratedContentDetails'
import GeneratedContentBorders from '@/views/cases/case/generated-content/GeneratedContentBorders'
import VxCard from '@/components/VxCard'
import GenerationContentItems from '@/views/cases/case/generated-content/GenerationContentItems'
import GenerationContentCategories from '@/views/cases/case/generated-content/GenerationContentCategories'
import { cases, useResource } from '@/services/resources'
import useCaseStore from '@/views/cases/case/useCaseStore'
import { chestDataResolverResource } from '@/views/cases/case/caseDataResolver'

const dataResolver = (to, from, next) => {
  const include = [
    'activeFeast', 'type', 'icon', 'chestCategories', 'chestCategories.category', 'chestSections',
    'chestSections.section', 'lastActiveGeneration', 'lastActiveGeneration.categories',
    'lastActiveGeneration.contents', 'lastActiveGeneration.contents.item', 'lastGeneration'
  ]
  return routerDataResolver(
    to,
    next,
    chestDataResolverResource(to.params.id, include)
  )
}

export default {
  name: 'GeneratedContent',
  components: {
    CaseContainer: () => import('@/views/cases/case/CaseContainer'),
    GenerationContentCategories,
    GenerationContentItems,
    GeneratedContentBorders,
    GeneratedContentDetails,
    VxButton,
    VxCard,
    BRow,
    BCol,
    BAlert,
    BTabs,
    BTab,
    BCardBody
  },
  beforeRouteEnter (to, from, next) {
    dataResolver(to, from, next)
  },
  beforeRouteUpdate (to, from, next) {
    dataResolver(to, from, next)
  },
  props: {
    dataResolverResponse: {
      type: Object,
      required: true
    }
  },
  setup ({ dataResolverResponse }) {
    const caseStore = useCaseStore(dataResolverResponse)
    provide('caseStore', caseStore)

    const {
      isChestActive,
      chest,
      lastGenerationId,
      lastActiveGenerationId,
      lastGenerationUrl,
      lastSuccessfulGenerationUrl
    } = caseStore

    const { route, router } = useRouter()

    const {
      can: canGenerate,
      loading: generationLoading,
      callRequest: createGenerationRequest
    } = useResource(cases.createGeneration)

    const generationId = computed(() => {
      const id = route.value?.params?.generation_id
      return id ? Number(id) : null
    })

    const goToLastGenerations = async () => {
      await router.push(lastGenerationUrl.value)
    }

    if (!generationId.value && lastGenerationId.value) {
      goToLastGenerations()
    }

    const wdsUrl = process.env.VUE_APP_WDS_URL

    const showLastContent = computed(() => {
      return lastGenerationId.value && lastGenerationId.value !== generationId.value
    })

    const showLastSuccessfulGeneration = computed(() => {
      return lastActiveGenerationId.value && lastActiveGenerationId.value !== generationId.value
    })

    const generationButtonText = computed(() => {
      return generationId.value ? 'Regenerate' : 'Generate'
    })

    const generate = async () => {
      const [err, res] = await createGenerationRequest({ urlParams: { id: chest.id } })
      if (err) return

      await router.push(buildRoute(path.caseGeneratedContent, {
        params: {
          id: chest.id,
          generation_id: res.data.id
        }
      }))
    }

    return {
      isChestActive,
      generationId,

      wdsUrl,
      showLastContent,
      lastGenerationUrl,
      showLastSuccessfulGeneration,
      lastSuccessfulGenerationUrl,

      generationButtonText,
      canGenerate,
      generationLoading,
      generate
    }
  }
}
</script>
