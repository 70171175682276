<template>
  <b-modal
    v-model="modalIsOpen"
    :title="modalTitle"
    hide-footer
    title-tag="h4"
    @close="closeModal"
  >
    <border-editor
      :border="entity"
      :border-max="borderMax"
      @submit="handleSubmit"
    />
  </b-modal>
</template>

<script>
import BorderEditor from '@/views/cases/case/provably-fair/BorderEditor'
import { BModal } from 'bootstrap-vue'
import { useModalEditor } from '@/services/modal'
import { computed } from '@vue/composition-api'

export default {
  name: 'BorderModal',
  components: {
    BModal,
    BorderEditor
  },
  props: {
    borderMax: {
      type: Number,
      required: true
    }
  },
  setup (props, { emit }) {
    const { modalIsOpen, entity, openModal, closeModal } = useModalEditor()
    const modalTitle = computed(() => entity.value ? 'Update border' : 'Create border')

    const handleSubmit = (res) => {
      emit('submit', res)
      closeModal()
    }

    return {
      modalIsOpen,
      modalTitle,
      entity,
      openModal,
      closeModal,
      handleSubmit
    }
  }
}
</script>
