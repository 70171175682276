<template>
  <vx-form
    v-slot="{ loading }"
    :resource="formResource"
    @submit="handleSubmit"
  >
    <vx-input
      v-model="formValue.max_price"
      :rules="rules.max_price"
      label="Border max ($)"
      name="max_price"
      type="number"
      step="0.01"
    />
    <vx-input
      v-model="formValue.min_skins"
      :rules="rules.min_skins"
      label="Minimum items"
      name="min_skins"
      type="number"
    />
    <vx-input
      v-model="formValue.max_skins"
      :rules="rules.max_skins"
      label="Maximum items"
      name="max_skins"
      type="number"
    />
    <vx-button
      :loading="loading"
      variant="primary"
      type="submit"
    >
      Save
    </vx-button>
  </vx-form>
</template>

<script>
import { VxForm, VxInput } from '@/components/form'
import VxButton from '@/components/buttons/VxButton'
import { reactive, inject } from '@vue/composition-api'
import { cases, passDataToResource } from '@/services/resources'
import { formatToCents, formatToDecimal } from '@/services/utils'
import { setValuesToForm } from '@/services/form'

export default {
  name: 'BorderEditor',
  components: {
    VxForm,
    VxInput,
    VxButton
  },
  props: {
    border: {
      type: Object,
      default: null
    },
    borderMax: {
      type: Number,
      required: true
    }
  },
  emits: ['submit'],
  setup ({ border, borderMax }, { emit }) {
    const { chest } = inject('caseStore')

    const isEdit = !!border

    const frontToBackMapper = ({ max_price, ...rest }) => ({
      ...rest,
      max_price: formatToCents(max_price)
    })

    const formResource = isEdit
      ? passDataToResource(cases.updateBorder, {
        requestParams: {
          urlParams: { chestId: chest.id, borderId: border.id }
        },
        frontToBackMapper
      })
      : passDataToResource(cases.createBorder, {
        requestParams: {
          urlParams: { id: chest.id }
        },
        frontToBackMapper
      })

    const formValue = reactive({
      max_price: '',
      min_skins: '',
      max_skins: ''
    })

    const rules = {
      max_price: {
        required: true,
        decimal: 2,
        min_value: 0.01,
        max_value: formatToDecimal(borderMax)
      },
      min_skins: {
        required: true,
        numeric: true,
        min_value: 1
      },
      max_skins: {
        required: true,
        numeric: true,
        is_bigger: 'min_skins',
        min_value: 1
      }
    }

    const backToFrontMapper = ({ max_price, ...rest }) => ({
      ...rest,
      max_price: formatToDecimal(max_price)
    })

    if (isEdit) {
      setValuesToForm(backToFrontMapper(border), formValue)
    }

    const handleSubmit = ([err, res]) => {
      if (err) return

      emit('submit', res)
    }

    return {
      formResource,
      formValue,
      rules,
      handleSubmit
    }
  }
}
</script>
