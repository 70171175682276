<template>
  <vx-table
    :resource="resource"
    :columns="columns"
    :filters="filters"
    :prefetch="getBaseInfo"
    suppress-route-handling
  />
</template>

<script>
import { VxCellBadges, VxCellImageWithTitle, VxCellRarityBadge, VxTable } from '@/components/table'
import { inject } from '@vue/composition-api'
import { boolStatuses, cases, passDataToResource } from '@/services/resources'
import { buildRoute, path } from '@/router'
import { boolStatusFormat, chanceFormat, getMainPrice, moneyFormat } from '@/services/utils'
import { filterTypes } from '@/services/table'

export default {
  name: 'GenerationContentItems',
  components: {
    VxTable,
    VxCellImageWithTitle,
    VxCellBadges
  },
  props: {
    generationId: {
      type: Number,
      required: true
    }
  },
  setup ({ generationId }) {
    const {
      chest,
      getBaseInfo,
      exteriorFormatter
    } = inject('caseStore')

    const resource = passDataToResource(cases.getGenerationContent, {
      requestParams: {
        urlParams: { chestId: chest.id, generationId },
        params: { include: 'item,item.category,item.entity,item.file,item.category.file' }
      }
    })

    const columns = [
      { key: 'id' },
      {
        key: 'item',
        label: 'Title',
        component: VxCellImageWithTitle,
        tdAttr: (value) => {
          const { file: { external_link }, id, title } = value
          return {
            label: title,
            href: external_link,
            id: id,
            link: buildRoute(path.items, { query: { id } })
          }
        }
      },
      {
        key: 'item.entity.is_stattrak',
        label: 'Is stattrak',
        component: VxCellBadges,
        tdAttr: {
          colors: {
            [boolStatuses['1']]: 'success',
            [boolStatuses['0']]: 'warning'
          }
        },
        formatter: (value) => boolStatusFormat(value)
      },
      {
        key: 'item.entity.is_souvenir',
        label: 'Is souvenir',
        component: VxCellBadges,
        tdAttr: {
          colors: {
            [boolStatuses['1']]: 'success',
            [boolStatuses['0']]: 'warning'
          }
        },
        formatter: (isSouvenir) => boolStatusFormat(isSouvenir)
      },
      {
        key: 'item.entity.exterior',
        label: 'Exterior',
        formatter: exteriorFormatter
      },
      {
        key: 'item.category.rarity',
        label: 'Rarity',
        component: VxCellRarityBadge
      },
      {
        key: 'fixed_price',
        label: 'Fixed price',
        sortable: true,
        formatter: (value, key, { item: { prices } }) => {
          return moneyFormat(getMainPrice(prices), { withSpace: false })
        }
      },
      {
        key: 'range_min',
        sortable: true
      },
      {
        key: 'range_max',
        sortable: true
      },
      {
        key: 'chance',
        sortable: true,
        formatter: chanceFormat
      }
    ]

    const filters = [
      {
        key: 'item_id',
        type: filterTypes.number
      },
      {
        key: 'category_id',
        type: filterTypes.number
      },
      {
        key: 'random_number',
        label: 'Range',
        type: filterTypes.number
      }
    ]

    return {
      resource,
      columns,
      filters,
      getBaseInfo
    }
  }
}
</script>
