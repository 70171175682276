<template>
  <vx-table
    :resource="resource"
    :columns="columns"
    suppress-route-handling
  >
    <template #title>
      Borders
    </template>
  </vx-table>
</template>

<script>
import { inject } from '@vue/composition-api'
import { cases, passDataToResource } from '@/services/resources'
import { VxCellMoney, VxTable } from '@/components/table'
import { chanceFormat, moneyFormat } from '@/services/utils'

export default {
  name: 'GeneratedContentBorders',
  components: {
    VxTable,
    VxCellMoney
  },
  props: {
    generationId: {
      type: Number,
      required: true
    }
  },
  setup ({ generationId }) {
    const { chest } = inject('caseStore')

    const resource = passDataToResource(cases.getGenerationBorders, {
      requestParams: {
        urlParams: { chestId: chest.id, generationId }
      }
    })

    const columns = [
      {
        key: 'min_price',
        component: VxCellMoney
      },
      {
        key: 'max_price',
        component: VxCellMoney
      },
      {
        key: 'generation_item_count',
        label: 'Item amount (available)',
        formatter: (value, key, { generation_available_item_count }) => {
          return `${value} (${generation_available_item_count})`
        }
      },
      {
        key: 'generation_category_count',
        label: 'Category amount (available)',
        formatter: (value, key, { generation_available_item_count }) => {
          return `${value} (${generation_available_item_count})`
        }
      },
      {
        key: 'generation_real_price_min',
        label: 'Real price range',
        formatter: (value, key, { generation_real_price_max }) => {
          return `${moneyFormat(value, { withSpace: false })} - ${moneyFormat(generation_real_price_max, { withSpace: false })}`
        }
      },
      {
        key: 'generation_total_chance',
        label: 'Total chance',
        formatter: chanceFormat
      }
    ]

    return {
      resource,
      columns
    }
  }
}
</script>
