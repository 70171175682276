<template>
  <vx-card
    :loading="getCaseCategoriesLoading"
    :can="canGetCaseCategories"
    no-body
  >
    <template #title>
      Selected categories
    </template>
    <template #actions>
      <div>
        <vx-button
          class="mr-1"
          variant="primary"
          @click="reverseOrderOfSelected"
        >
          Reverse order
        </vx-button>
        <vx-button
          variant="primary"
          :can="canUpdateCaseCategories"
          :loading="updateCaseCategoriesLoading"
          @click="updateCaseCategories"
        >
          Save
        </vx-button>
      </div>
    </template>
    <b-card-body>
      <draggable
        :list="selectedCategories"
        tag="div"
        class="d-flex flex-wrap justify-content-center"
        group="categories"
      >
        <category-item-card
          v-for="(item, id) in selectedCategories"
          :key="id"
          :category="item"
          class="m-50"
          remove-button
          @remove="removeFromSelected"
        />
      </draggable>
    </b-card-body>
  </vx-card>
</template>

<script>
import VxCard from '@/components/VxCard'
import VxButton from '@/components/buttons/VxButton'
import { BCardBody } from 'bootstrap-vue'
import Draggable from 'vuedraggable'
import { inject } from '@vue/composition-api'
import CategoryItemCard from '@/views/skins/CategoryItemCard'

export default {
  name: 'SelectedCategories',
  components: {
    CategoryItemCard,
    VxButton,
    VxCard,
    BCardBody,
    Draggable
  },
  setup () {
    const {
      canGetCaseCategories,
      getCaseCategoriesLoading,
      selectedCategories,

      removeFromSelected,
      reverseOrderOfSelected,

      canUpdateCaseCategories,
      updateCaseCategoriesLoading,
      updateCaseCategories
    } = inject('contentBuilderStore')

    return {
      canGetCaseCategories,
      getCaseCategoriesLoading,
      selectedCategories,

      reverseOrderOfSelected,
      removeFromSelected,

      canUpdateCaseCategories,
      updateCaseCategoriesLoading,
      updateCaseCategories
    }
  }
}
</script>
