<template>
  <case-container>
    <borders-slider
      :borders="borders"
      :border-max="borderMax"
      @update="tableRef.refresh()"
    />
    <vx-table
      ref="tableRef"
      :resource="tableResource"
      :columns="columns"
      suppress-route-handling
    >
      <template #title>
        Borders
      </template>
      <template #actions>
        <div>
          <vx-button
            variant="primary"
            class="mr-1"
            :can="canUpdateGenerationSettings"
            @click="openGenerationSettingsModal()"
          >
            Generation settings
          </vx-button>
          <vx-button
            variant="primary"
            :can="canCreate"
            @click="openBorderModal()"
          >
            Add border
          </vx-button>
        </div>
      </template>
    </vx-table>
    <border-modal
      ref="borderModalRef"
      :border-max="borderMax"
      @submit="handleBorderSubmit"
    />
    <generation-settings-modal
      ref="generationSettingsModalRef"
    />
  </case-container>
</template>

<script>
import { VxCellButtons, VxCellDate, VxCellMoney, VxTable } from '@/components/table'
import { computed, provide, ref, onBeforeMount } from '@vue/composition-api'
import { cases, passDataToResource, useResource } from '@/services/resources'
import VxButton from '@/components/buttons/VxButton'
import { BModal } from 'bootstrap-vue'
import BorderEditor from '@/views/cases/case/provably-fair/BorderEditor'
import { buttons } from '@/components/table/vx-table-cells/vx-cell-button'
import BordersSlider from '@/views/cases/case/provably-fair/BordersSlider'
import BorderModal from '@/views/cases/case/provably-fair/BorderModal'
import GenerationSettingsModal from '@/views/cases/case/provably-fair/GenerationSettingsModal'
import useCaseStore from '@/views/cases/case/useCaseStore'

export default {
  name: 'ProvablyFair',
  components: {
    CaseContainer: () => import('@/views/cases/case/CaseContainer'),
    GenerationSettingsModal,
    BorderModal,
    BordersSlider,
    VxButton,
    VxTable,
    BorderEditor,
    BModal
  },
  props: {
    dataResolverResponse: {
      type: Object,
      required: true
    }
  },
  setup ({ dataResolverResponse }) {
    const caseStore = useCaseStore(dataResolverResponse)
    provide('caseStore', caseStore)

    const { chest } = caseStore

    const tableRef = ref(null)
    const borders = computed(() => tableRef.value?.items || [])

    const borderModalRef = ref(null)
    const openBorderModal = (entity) => {
      borderModalRef.value.openModal(entity)
    }

    const generationSettingsModalRef = ref(null)
    const openGenerationSettingsModal = () => {
      generationSettingsModalRef.value.openModal()
    }

    const { can: canCreate } = useResource(cases.createBorder)
    const { can: canUpdate } = useResource(cases.updateBorder)
    const { can: canUpdateGenerationSettings } = useResource(cases.update)

    const tableResource = passDataToResource(cases.borders, {
      requestParams: {
        urlParams: { id: chest.id },
        params: { include: 'itemCounters' }
      }
    })

    const { callRequest: getBorderSettingsRequest } = useResource(cases.bordersSettings)
    const borderMax = ref(0)

    const getBorderSettings = async () => {
      const [err, res] = await getBorderSettingsRequest({
        urlParams: { id: chest.id }
      })
      if (err) return

      borderMax.value = res.data.max_allowed_border_price
    }

    const columns = [
      { key: 'id' },
      {
        key: 'max_price',
        label: 'Border max',
        component: VxCellMoney
      },
      {
        key: 'min_skins',
        label: 'Minimum items'
      },
      {
        key: 'max_skins',
        label: 'Maximum items'
      },
      {
        key: 'item_count',
        label: 'Total items (Available)',
        formatter: (value, key, { available_item_count }) => itemsFormatter(value, available_item_count)
      },
      {
        key: 'required_item_count',
        label: 'Required items (Available)',
        formatter: (value, key, { available_required_item_count }) => itemsFormatter(value, available_required_item_count)
      },
      {
        key: 'disabled_item_count',
        label: 'Disabled items (Available)',
        formatter: (value, key, { available_disabled_item_count }) => itemsFormatter(value, available_disabled_item_count)
      },
      {
        key: 'created_at',
        component: VxCellDate
      },
      {
        key: 'updated_at',
        component: VxCellDate
      },
      {
        key: 'actions',
        component: VxCellButtons,
        tdAttr: (value, key, item) => ({
          buttons: actionButtons(item)
        })
      }
    ]

    const itemsFormatter = (count, availableCount) => {
      if (!count) return 0

      return `${count} (${availableCount})`
    }

    const actionButtons = ({ id, chest_id }) => {
      const editButton = {
        ...buttons.edit,
        can: canUpdate,
        onClick: openBorderModal
      }

      const deleteButton = {
        ...buttons.delete,
        resource: passDataToResource(cases.deleteBorder, {
          requestParams: { urlParams: { chestId: chest_id, borderId: id } }
        })
      }

      return [editButton, deleteButton]
    }

    const handleBorderSubmit = () => {
      tableRef.value.refresh()
    }

    onBeforeMount(() => {
      getBorderSettings()
    })

    return {
      tableRef,
      tableResource,
      columns,

      canCreate,
      borderModalRef,
      openBorderModal,
      handleBorderSubmit,

      canUpdateGenerationSettings,
      generationSettingsModalRef,
      openGenerationSettingsModal,

      borders,
      borderMax
    }
  }
}
</script>
