import { buildRoute, path } from '@/router'
import CaseDetails from '@/views/cases/case/details/CaseDetails'
import ContentBuilder from '@/views/cases/case/content-builder/ContentBuilder'
import ItemsSettings from '@/views/cases/case/items-settings/ItemsSettings'
import ProvablyFair from '@/views/cases/case/provably-fair/ProvablyFair'
import GeneratedContent from '@/views/cases/case/generated-content/GeneratedContent'
import GenerationHistory from '@/views/cases/case/generation-history/GenerationHistory'
import { cases, passDataToResource } from '@/services/resources'
import { buttons } from '@/components/table/vx-table-cells/vx-cell-button'
import { useSkin } from '@/services/app'

export const casePages = (id) => {
  const pageHashes = {
    details: 'details',
    contentBuilder: 'content-builder',
    itemsSettings: 'items-settings',
    pfCaseSettings: 'pf-case-settings',
    generatedContent: 'generated-content',
    generationHistory: 'generation-history'
  }

  const pages = [
    {
      icon: 'EyeIcon',
      label: 'Details',
      to: buildRoute(path.caseDetails, {
        params: { id }
      }),
      component: CaseDetails,
      hash: pageHashes.details
    },
    {
      icon: 'LayoutIcon',
      label: 'Content builder',
      to: buildRoute(path.caseContentBuilder, {
        params: { id }
      }),
      component: ContentBuilder,
      hash: pageHashes.contentBuilder
    },
    {
      icon: 'SettingsIcon',
      label: 'Items settings',
      to: buildRoute(path.caseItemsSettings, {
        params: { id }
      }),
      component: ItemsSettings,
      hash: pageHashes.itemsSettings
    },
    {
      icon: 'TargetIcon',
      label: 'PF case settings',
      to: buildRoute(path.casePFSettings, {
        params: { id }
      }),
      component: ProvablyFair,
      hash: pageHashes.pfCaseSettings
    },
    {
      icon: 'BoxIcon',
      label: 'Generated content',
      to: buildRoute(path.caseGeneratedContent, {
        params: { id }
      }),
      component: GeneratedContent,
      hash: pageHashes.generatedContent
    },
    {
      icon: 'ArchiveIcon',
      label: 'Generation history',
      to: buildRoute(path.caseContentHistory, {
        params: { id }
      }),
      component: GenerationHistory,
      hash: pageHashes.generationHistory
    }
  ]

  return {
    pages,
    pageHashes
  }
}

export const statusButton = ({ id, is_disabled }) => {
  const updatedStatus = is_disabled ? 'enable' : 'disable'
  const payload = {
    requestParams: {
      urlParams: {
        id,
        status: updatedStatus
      }
    }
  }

  const updatedStatusResource = passDataToResource(cases.statusToggle, payload)

  const turnOff = { ...buttons.turnOff, label: 'Enabled' }
  const turnOn = { ...buttons.turnOn, label: 'Disabled' }

  return {
    ...is_disabled ? turnOn : turnOff,
    resource: updatedStatusResource
  }
}

const { skin, skins } = useSkin()
export const getProfitabilityClasses = (value) => {
  const profitability = value || 0

  const warningCondition = profitability < 0.7 || (profitability >= 0.95 && profitability <= 1)
  const successCondition = profitability >= 0.7 && profitability < 0.95

  let backgroundClass = ''

  switch (true) {
    case warningCondition:
      backgroundClass = 'bg-warning'
      break
    case successCondition:
      backgroundClass = 'bg-success'
      break
    default:
      backgroundClass = 'bg-danger'
  }

  const accentClasses = {
    [skins.dark]: 'bg-darken-5',
    [skins.bordered]: 'bg-lighten-5'
  }
  const accentClass = accentClasses[skin.value]

  return [
    backgroundClass,
    accentClass
  ]
}
