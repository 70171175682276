<template>
  <vx-card :loading="updateLoading">
    <div class="d-flex justify-content-between align-items-end flex-nowrap">
      <div>
        Min: {{ borderMinFormatted }}
      </div>
      <vue-slider
        ref="sliderRef"
        :value="bordersPoints"
        class="flex-grow-1 mt-2 mx-2"
        tooltip="always"
        :disabled="!canUpdate"
        :max="borderMax"
        :tooltip-formatter="tooltipFormatter"
        @change="change"
      />
      <div>
        Max: {{ borderMaxFormatted }}
      </div>
    </div>
  </vx-card>
</template>

<script>
import VueSlider from 'vue-slider-component'
import { computed, ref, inject } from '@vue/composition-api'
import { moneyFormat } from '@/services/utils'
import VxCard from '@/components/VxCard'
import { cases, useResource } from '@/services/resources'
import { debounce } from 'lodash'

export default {
  name: 'BordersSlider',
  components: {
    VueSlider,
    VxCard
  },
  props: {
    borders: {
      type: Array,
      required: true
    },
    borderMax: {
      type: Number,
      required: true
    }
  },
  setup (props, { emit }) {
    const {
      can: canUpdate,
      loading: updateLoading,
      callRequest: borderUpdateRequest
    } = useResource(cases.updateBorder)

    const { chest } = inject('caseStore')

    const borderMaxFormatted = computed(() => moneyFormat(props.borderMax))
    const borderMinFormatted = moneyFormat(0)

    const bordersPoints = computed(() => props.borders.map(({ max_price }) => max_price))

    const sliderRef = ref(null)

    const tooltipFormatter = (value) => {
      return moneyFormat(value)
    }

    const frontToBackMapper = ({ min_skins, max_skins }, newMaxPrice) => ({
      min_skins,
      max_skins,
      max_price: newMaxPrice
    })

    const updateBorder = async (border, newMaxPrice) => {
      const payload = {
        data: frontToBackMapper(border, newMaxPrice),
        urlParams: {
          chestId: chest.id,
          borderId: border.id
        }
      }
      const [err, res] = await borderUpdateRequest(payload)
      if (err) return

      emit('update', res)
    }

    const change = debounce((values, index) => {
      const newMaxPrice = Array.isArray(values) ? values[index] : values
      updateBorder(props.borders[index], newMaxPrice)
    }, 300)

    return {
      borderMinFormatted,
      borderMaxFormatted,

      sliderRef,
      tooltipFormatter,

      canUpdate,
      bordersPoints,
      change,
      updateLoading
    }
  }
}
</script>
