<template>
  <div>
    <vx-table-simple
      :items="items"
      :columns="columns"
      stacked
    >
      <template #title>
        Case info
      </template>
      <template #actions>
        <vx-button
          :can="canUpdate"
          @click="openModal"
        >
          Edit
        </vx-button>
      </template>
    </vx-table-simple>
    <b-modal
      v-model="modalIsOpen"
      title="Update case"
      hide-footer
      title-tag="h4"
    >
      <case-update-editor @submit="closeModal" />
    </b-modal>
  </div>
</template>

<script>
import { inject } from '@vue/composition-api'
import { caseLabelDefault, cases, digitalOceanPath, useResource } from '@/services/resources'
import {
  VxCellButtons,
  VxCellDate,
  VxCellImage,
  VxCellJsonViewer,
  VxCellMoney,
  VxTableSimple
} from '@/components/table'
import { BModal } from 'bootstrap-vue'
import CaseUpdateEditor from '@/views/cases/case/details/CaseUpdateEditor'
import { useModalEditor } from '@/services/modal'
import { VxButton } from '@/components/buttons'
import { statusButton } from '@/views/cases/casesService'

export default {
  name: 'CaseDetailsTable',
  components: {
    VxTableSimple,
    VxCellMoney,
    VxCellDate,
    VxCellImage,
    VxCellJsonViewer,
    VxButton,
    CaseUpdateEditor,
    BModal
  },
  setup () {
    const { modalIsOpen, entity, openModal, closeModal } = useModalEditor()
    const { can: canUpdate } = useResource(cases.update)

    const { chest, updateChest } = inject('caseStore')

    const items = [chest]
    const columns = [
      { key: 'id' },
      { key: 'title' },
      {
        key: 'type_name',
        label: 'Type'
      },
      {
        key: 'label',
        formatter: (value) => {
          return value || caseLabelDefault
        }
      },
      {
        key: 'main_price',
        component: VxCellMoney
      },
      {
        key: 'options.price',
        label: 'Price (BRC)',
        component: VxCellMoney
      },
      {
        key: 'icon.path',
        label: 'Icon',
        component: VxCellImage,
        formatter: digitalOceanPath
      },
      {
        key: 'is_disabled',
        label: 'Status',
        component: VxCellButtons,
        tdAttr: (value, key, item) => ({
          buttons: [{
            ...statusButton(item),
            onClick: handleChangeStatus
          }]
        })
      },
      {
        key: 'last_active_generation.created_at',
        label: 'Last successful generation',
        component: VxCellDate
      },
      {
        key: 'wds_parameters',
        component: VxCellJsonViewer
      }
    ]

    const handleChangeStatus = (item, [err, res]) => {
      if (err) return

      updateChest(res.data, 'is_disabled')
    }

    return {
      items,
      columns,

      canUpdate,
      modalIsOpen,
      entity,
      openModal,
      closeModal
    }
  }
}
</script>
