<template>
  <vx-table-simple
    :items="items"
    :columns="columns"
  >
    <template #title>
      Prices for players
    </template>
  </vx-table-simple>
</template>

<script>
import VxTableSimple from '@/components/table/vx-table-simple/VxTableSimple'
import { inject } from '@vue/composition-api'
import { formatToDecimal } from '@/services/utils'

export default {
  name: 'CasePrices',
  components: { VxTableSimple },
  setup () {
    const { chest } = inject('caseStore')

    const items = chest.prices

    const columns = [
      {
        key: 'amount',
        formatter: formatToDecimal
      },
      { key: 'currency' }
    ]

    return {
      items,
      columns
    }
  }
}
</script>
