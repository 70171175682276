<template>
  <case-container>
    <vx-table
      :resource="resource"
      :columns="columns"
      :filters="filters"
      suppress-route-handling
    />
  </case-container>
</template>

<script>
import { VxCellButtons, VxCellDate, VxCellMoney, VxCellTooltip, VxTable } from '@/components/table'
import { cases, passDataToResource } from '@/services/resources'
import { boolStatusesOptions, boolStatusFormat } from '@/services/utils'
import { filterTypes } from '@/services/table'
import { getProfitabilityClasses } from '@/views/cases/casesService'
import { buttons } from '@/components/table/vx-table-cells/vx-cell-button'
import { buildRoute, path } from '@/router'

export default {
  name: 'GenerationHistory',
  components: {
    CaseContainer: () => import('@/views/cases/case/CaseContainer'),
    VxTable,
    VxCellMoney,
    VxCellTooltip,
    VxCellButtons
  },
  props: {
    dataResolverResponse: {
      type: Object,
      required: true
    }
  },
  setup ({ dataResolverResponse: chest }) {
    const resource = passDataToResource(cases.getAllGenerations, {
      requestParams: { urlParams: { id: chest.id } }
    })

    const columns = [
      {
        key: 'id'
      },
      { key: 'uid' },
      {
        key: 'chest_price',
        label: 'Case price',
        component: VxCellMoney
      },
      {
        key: 'is_disabled',
        label: 'Disabled',
        formatter: boolStatusFormat
      },
      {
        key: 'is_published',
        label: 'Published',
        formatter: boolStatusFormat
      },
      {
        key: 'is_auto',
        label: 'Auto',
        formatter: boolStatusFormat
      },
      {
        key: 'is_failed',
        label: 'Failed',
        formatter: boolStatusFormat,
        component: VxCellTooltip,
        tdAttr: (name, key, { fail_reason }) => ({
          tooltipText: fail_reason
        })
      },
      {
        key: 'real_profitability',
        formatter: (value, key, { desired_profitability }) => {
          return `${value} (${desired_profitability})`
        },
        tdClass: (value) => getProfitabilityClasses(value)
      },
      {
        key: 'created_at',
        component: VxCellDate
      },
      {
        key: 'actions',
        component: VxCellButtons,
        tdAttr: (value, key, { id }) => ({
          buttons: [{
            ...buttons.view,
            to: buildRoute(path.caseGeneratedContent, {
              params: {
                id: chest.id,
                generation_id: id
              }
            })
          }]
        })
      }
    ]

    const filters = [
      {
        key: 'id',
        type: filterTypes.number
      },
      {
        key: 'uid',
        type: filterTypes.text
      },
      {
        key: 'is_auto',
        label: 'Auto',
        type: filterTypes.select,
        options: boolStatusesOptions,
        optionsLabel: 'label',
        reduce: ({ value }) => value
      },
      {
        key: 'is_manual',
        label: 'Manual',
        type: filterTypes.select,
        options: boolStatusesOptions,
        optionsLabel: 'label',
        reduce: ({ value }) => value
      },
      {
        key: 'is_failed',
        label: 'Failed',
        type: filterTypes.select,
        options: boolStatusesOptions,
        optionsLabel: 'label',
        reduce: ({ value }) => value
      },
      {
        key: 'is_published',
        label: 'Published',
        type: filterTypes.select,
        options: boolStatusesOptions,
        optionsLabel: 'label',
        reduce: ({ value }) => value
      },
      {
        key: 'is_retired',
        label: 'Retired',
        type: filterTypes.select,
        options: boolStatusesOptions,
        optionsLabel: 'label',
        reduce: ({ value }) => value
      },
      {
        key: 'created_at',
        type: filterTypes.dateRangePicker,
        apiKeys: ['from', 'to']
      }
    ]

    return {
      resource,
      columns,
      filters
    }
  }
}
</script>
