<template>
  <vx-form
    v-slot="{ loading }"
    :resource="formResource"
    @submit="handleSubmit"
  >
    <vx-input
      v-model="formValue.title"
      :rules="rules.title"
      label="Title"
      name="title"
    />
    <vx-input
      v-model="formValue.main_price"
      :rules="rules.main_price"
      label="Main price (USD)"
      type="number"
      step="0.01"
      min="0"
      name="main_price"
    />
    <vx-select
      v-model="formValue.label"
      :rules="rules.label"
      :options="caseLabelsList"
      options-label="label"
      name="label"
      label="Label"
    />
    <vx-input
      v-if="isBroCoinType"
      v-model="formValue.options.price.amount"
      :rules="rules.brc_priced"
      label="Price (BRC)"
      type="number"
      step="1"
      min="0"
      name="brc_priced"
    />
    <vx-image-uploader
      v-model="formValue.file_id"
      :rules="rules.file_id"
      :initial-src="initialIconPath"
      name="file_id"
      label="Icon"
    />
    <vx-json
      v-model="formValue.wds_parameters"
      label="WDS parameters"
      mode="code"
      :modes="['code']"
      name="wds_parameters"
    />

    <vx-button
      :loading="loading"
      variant="primary"
      type="submit"
    >
      Save
    </vx-button>
  </vx-form>
</template>

<script>
import { reactive, inject, computed, onBeforeMount, ref } from '@vue/composition-api'
import { caseLabelDefault, cases, digitalOceanPath, passDataToResource, useResource } from '@/services/resources'
import { apiDefaultValidators, frontDefaultValidators, setValuesToForm } from '@/services/form'
import { VxForm, VxInput, VxSelect, VxImageUploader, VxJson } from '@/components/form'
import { VxButton } from '@/components/buttons'
import { formatToCents, formatToDecimal } from '@/services/utils'
import { cloneDeep, isEmpty, isNull, pick } from 'lodash'

export default {
  name: 'CaseUpdateEditor',
  components: {
    VxJson,
    VxForm,
    VxImageUploader,
    VxInput,
    VxSelect,
    VxButton
  },
  setup (props, { emit }) {
    const { chest, isBroCoinType, updateChest } = inject('caseStore')

    const frontToBackMapper = (value) => {
      const getOptions = () => {
        if (!isBroCoinType.value) {
          return { options: chest.options }
        }

        const { amount, currency } = formValue.options.price
        return {
          options: {
            price: formatToCents(amount),
            currency_code: currency
          }
        }
      }

      const requiredFields = [
        'slug', 'title', 'main_price', 'is_disabled', 'is_manual', 'icon_type',
        'file_id', 'min_category_amount', 'generation_period', 'profitability'
      ]
      const requiredData = pick(chest, requiredFields)
      const chestTypeId = chest?.type?.id || null

      return {
        ...requiredData,
        ...value,
        main_price: formatToCents(formValue.main_price),
        label: formValue.label === caseLabelDefault ? null : formValue.label,
        wds_parameters: isEmpty(formValue.wds_parameters) ? null : formValue.wds_parameters,
        chest_type_id: chestTypeId,
        ...(chestTypeId && { ...getOptions() })
      }
    }

    const formResource = passDataToResource(cases.update, {
      frontToBackMapper,
      requestParams: {
        urlParams: { id: chest.id }
      }
    })

    const initialIconPath = computed(() => {
      return digitalOceanPath(chest.icon.path)
    })

    const caseLabelsList = ref([])
    const formValue = reactive({
      title: '',
      main_price: '',
      label: caseLabelDefault,
      file_id: null,
      wds_parameters: {},
      options: {}
    })

    const rules = {
      title: {
        required: true,
        min: 2,
        max: 255
      },
      main_price: {
        required: true,
        decimal: 2,
        min_value: 0.01,
        max_value: apiDefaultValidators.maxMediumIntUnsignedWithDecimal
      },
      label: {
        required: true
      },
      brc_priced: frontDefaultValidators.number
    }

    const backToFrontMapper = ({ main_price, wds_parameters, options, label, ...rest }) => {
      const res = {
        main_price: formatToDecimal(main_price),
        label: label || caseLabelDefault,
        wds_parameters: isNull(wds_parameters) ? {} : wds_parameters,
        ...rest
      }
      if (isBroCoinType.value) {
        res.options = {
          ...options
        }
        res.options.price.amount = formatToDecimal(options.price.amount)
      }
      return res
    }

    setValuesToForm(backToFrontMapper(cloneDeep(chest)), formValue)

    onBeforeMount(async () => {
      const {
        can: canLabels,
        callRequest: getLabels
      } = useResource(cases.labels)

      if (canLabels) {
        const [, res] = await getLabels()

        caseLabelsList.value = [caseLabelDefault, ...res.data]
      }
    })

    const handleSubmit = ([err, res]) => {
      if (err) return

      updateChest(res.data)
      emit('submit')
    }

    return {
      formResource,
      formValue,
      rules,

      initialIconPath,
      isBroCoinType,

      caseLabelsList,

      handleSubmit
    }
  }
}
</script>
