<template>
  <b-modal
    v-model="modalIsOpen"
    title="Generation settings"
    hide-footer
    title-tag="h4"
    @close="closeModal"
  >
    <generation-settings-editor
      @submit="handleSubmit"
    />
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue'
import GenerationSettingsEditor from '@/views/cases/case/provably-fair/GenerationSettingsEditor'
import { useModalEditor } from '@/services/modal'

export default {
  name: 'GenerationSettingsModal',
  components: {
    BModal,
    GenerationSettingsEditor
  },
  setup () {
    const { modalIsOpen, openModal, closeModal } = useModalEditor()

    const handleSubmit = () => {
      closeModal()
    }

    return {
      modalIsOpen,
      openModal,
      closeModal,
      handleSubmit
    }
  }
}
</script>
