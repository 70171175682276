import { computed, ref, unref, set } from '@vue/composition-api'
import { cloneDeep, get, merge } from 'lodash'
import { caseTypes } from '@/services/resources'
import useBaseInfo from '@/views/skins/useBaseInfo'
import { buildRoute, path } from '@/router'

const useCaseStore = (chest) => {

  const store = ref(cloneDeep(chest))

  const chestComputed = computed(() => store.value)

  const isBroCoinType = computed(() => store.value.type_name === caseTypes.brcPriced)
  const isChestActive = computed(() => !store.value?.is_disabled)
  const lastGenerationId = computed(() => store.value?.last_generation?.id)
  const lastActiveGenerationId = computed(() => store.value?.last_active_generation?.id)

  const lastGenerationUrl = computed(() => buildRoute(path.caseGeneratedContent, {
    params: {
      id: chest.id,
      generation_id: lastGenerationId.value
    }
  }))

  const lastSuccessfulGenerationUrl = computed(() => buildRoute(path.caseGeneratedContent, {
    params: {
      id: chest.id,
      generation_id: lastActiveGenerationId.value
    }
  }))

  const updateChest = (newChest, pathOfNewProperty) => {
    if (!pathOfNewProperty) {
      store.value = merge(store.value, newChest)
      return
    }

    set(store.value, pathOfNewProperty, get(newChest, pathOfNewProperty))
  }

  const {
    rarities,
    exteriors,
    exteriorEmptyKey,
    getBaseInfo,
    exteriorFormatter,
    rarityFormatter
  } = useBaseInfo()

  return {
    chest: unref(chestComputed),
    updateChest,

    isBroCoinType,
    isChestActive,
    lastGenerationId,
    lastActiveGenerationId,

    lastGenerationUrl,
    lastSuccessfulGenerationUrl,

    rarities,
    exteriors,
    exteriorEmptyKey,
    getBaseInfo,
    exteriorFormatter,
    rarityFormatter
  }
}

export default useCaseStore
